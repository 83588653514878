
import { SalesActionTypes } from './constants';



const INIT_STATE = {
    saleInvAdded: null,
    invUpdate: null,
    POSAdded: null,
    POSUpdate: null,
    invReturnAdded: null,
    invReturnUpdate: null,
    receipt: null,
    upd_receipt: null,
    pos_config:null,
    addCredit: null,
    updCredit: null,
    addDebit: null,
    updDebit: null,
    loading: false,
};

type InvoiceData = {
    name: string;
};


type SalesActionType = {
    type:
        | SalesActionTypes.API_RESPONSE_SUCCESS
        | SalesActionTypes.API_RESPONSE_ERROR
        | SalesActionTypes.ADD_Inv
        | SalesActionTypes.ADD_SALEINVOICE
        | SalesActionTypes.UPD_SALEINVOICE
        | SalesActionTypes.ADD_INVGRID
        | SalesActionTypes.PAYMENT_RECEIPT
        | SalesActionTypes.UPD_PAYMENT_RECEIPT
        | SalesActionTypes.ADD_INVOICERETURN
        | SalesActionTypes.UPD_INVOICERETURN
        | SalesActionTypes.ADD_POS
        | SalesActionTypes.UPD_POS
        | SalesActionTypes.CREDIT_NOTE
        | SalesActionTypes.UPD_CREDIT_NOTE
        | SalesActionTypes.UPD_POSCONFIG
        | SalesActionTypes.DEBIT_NOTE
        | SalesActionTypes.UPD_DEBIT
        | SalesActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: InvoiceData | {};
        error?: string;
    };
};

type State = {
    loading?: boolean;
    value?: boolean;
};

const Sales = (state: State = INIT_STATE, action: SalesActionType): any => {
    switch (action.type) {
        case SalesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SalesActionTypes.ADD_SALEINVOICE: {
                    return {
                        ...state,
                        saleInvAdded: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_SALEINVOICE: {
                    return {
                        ...state,
                        invUpdate: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.ADD_POS: {
                    return {
                        ...state,
                        POSAdded: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_POS: {
                    return {
                        ...state,
                        POSUpdate: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.ADD_INVOICERETURN: {
                    return {
                        ...state,
                        invReturnAdded: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_INVOICERETURN: {
                    return {
                        ...state,
                        invReturnUpdate: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.PAYMENT_RECEIPT: {
                    return {
                        ...state,
                        receipt: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_PAYMENT_RECEIPT: {
                    return {
                        ...state,
                        upd_receipt: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_POSCONFIG: {
                    return {
                        ...state,
                        pos_config: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.CREDIT_NOTE: {
                    return {
                        ...state,
                        addCredit: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_CREDIT_NOTE: {
                    return {
                        ...state,
                        updCredit: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.DEBIT_NOTE: {
                    return {
                        ...state,
                        addDebit: action.payload,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_DEBIT: {
                    return {
                        ...state,
                        updDebit: action.payload,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case SalesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case SalesActionTypes.ADD_Inv: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invAdded: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.ADD_SALEINVOICE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        saleInvAdded: null,
                        loading: true,
                    };
                }
                case SalesActionTypes.UPD_SALEINVOICE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invUpdate: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.ADD_POS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        POSAdded: null,
                        loading: true,
                    };
                }
                case SalesActionTypes.UPD_POS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        POSUpdate: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.ADD_INVOICERETURN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invReturnAdded: null,
                        loading: true,
                    };
                }
                case SalesActionTypes.UPD_INVOICERETURN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invReturnUpdate: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.PAYMENT_RECEIPT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        receipt: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_PAYMENT_RECEIPT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        upd_receipt: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.UPD_POSCONFIG: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        pos_config: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.ADD_INVGRID: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invGridAdded: null,
                        loading: false,
                    };
                }
                case SalesActionTypes.CREDIT_NOTE:
                    return { ...state, loading: true, addCredit: null };
                case SalesActionTypes.UPD_CREDIT_NOTE:
                    return { ...state, loading: true, updCredit: null };
                case SalesActionTypes.DEBIT_NOTE:
                    return { ...state, loading: true, addDebit: null };
                case SalesActionTypes.UPD_DEBIT:
                    return { ...state, loading: true, updDebit: null };
                
                default:
                    return { ...state };
            }

        case SalesActionTypes.ADD_Inv:
            return { ...state, loading: true, invAdded: null };
/*        case SalesActionTypes.ADD_SALEINVOICE:
            return { ...state, loading: true, saleInvAdded: null };*/
        case SalesActionTypes.UPD_SALEINVOICE:
            return { ...state, loading: true, invUpdate: null };
        case SalesActionTypes.ADD_POS:
            return { ...state, loading: true, POSAdded: null };
/*        case SalesActionTypes.ADD_SALEINVOICE:
            return { ...state, loading: true, saleInvAdded: null };*/
        case SalesActionTypes.UPD_POS:
            return { ...state, loading: true, POSUpdate: null };
/*        case SalesActionTypes.ADD_INVOICERETURN:
            return { ...state, loading: true, invReturnAdded: null };*/
        case SalesActionTypes.UPD_INVOICERETURN:
            return { ...state, loading: true, invReturnUpdate: null };
        case SalesActionTypes.ADD_INVGRID:
            return { ...state, loading: true, invGridAdded: null };
        case SalesActionTypes.UPD_PAYMENT_RECEIPT:
            return { ...state, loading: true, upd_receipt: null };
        case SalesActionTypes.UPD_POSCONFIG:
            return { ...state, loading: true, pos_config: null };
        case SalesActionTypes.PAYMENT_RECEIPT:
            return { ...state, loading: true, receipt: null };
        case SalesActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                invAdded: null,
                saleInvAdded: null,
                invReturnAdded: null,
                invReturnUpdate: null,
                invUpdate: null,
                POSAdded: null,
                POSUpdate: null,
                addCredit: null,
                updCredit: null,
                addDebit: null,
                updDebit: null,
                receipt: null,
                upd_receipt: null,
                pos_config:null,
                invGridAdded: null,
             };
        default:
            return { ...state };
    }
};

export default Sales;
