
import { PurchaseActionTypes } from './constants';



const INIT_STATE = {
    purchaseInvAdded: null,
    purchaseInvReturn: null,
    purReturnUpdate: null,
    invUpdate: null,
    receipt: null,
    upd_receipt: null,
    addCredit:null,
    addedServiceBill:null,
    updateServiceBill:null,
    updCredit:null,
    addDebit:null,
    updDebit:null,
    loading: false,
};

type InvoiceData = {
    name: string;
};


type SalesActionType = {
    type:
        | PurchaseActionTypes.API_RESPONSE_SUCCESS
        | PurchaseActionTypes.API_RESPONSE_ERROR
        | PurchaseActionTypes.ADD_Purchase
        | PurchaseActionTypes.ADD_PURCHASEINV
        | PurchaseActionTypes.UPD_PURCHASEINV
        | PurchaseActionTypes.ADD_PURCHASEGRID
        | PurchaseActionTypes.SUPPLIER_PAYMENT
        | PurchaseActionTypes.UPD_SUPPLIER_PAYMENT
        | PurchaseActionTypes.UPD_PURCHASE_RETURN
        | PurchaseActionTypes.ADD_PURCHASE_RETURN
        | PurchaseActionTypes.ADD_SERVICE_BILL
        | PurchaseActionTypes.UPD_SERVICES_BILL
        | PurchaseActionTypes.SUPP_CREDIT_NOTE
        | PurchaseActionTypes.SUPP_DEBIT_NOTE
        | PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE
        | PurchaseActionTypes.SUPP_UPD_DEBIT
        | PurchaseActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: InvoiceData | {};
        error?: string;
    };
};

type State = {
    loading?: boolean;
    value?: boolean;
};

const Purchase = (state: State = INIT_STATE, action: SalesActionType): any => {
    switch (action.type) {
        case PurchaseActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
            
                case PurchaseActionTypes.SUPP_CREDIT_NOTE: {
                    return {
                        ...state,
                        addCredit: action.payload,
                        loading: false,
                    };
                }
                
                case PurchaseActionTypes.ADD_SERVICE_BILL: {
                    return {
                        ...state,
                        addedServiceBill: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.UPD_SERVICES_BILL: {
                    return {
                        ...state,
                        updateServiceBill: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE: {
                    return {
                        ...state,
                        updCredit: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPP_DEBIT_NOTE: {
                    return {
                        ...state,
                        addDebit: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPP_UPD_DEBIT: {
                    return {
                        ...state,
                        updDebit: action.payload,
                        loading: false,
                    };
                }
            
                case PurchaseActionTypes.ADD_PURCHASEINV: {
                    return {
                        ...state,
                        purchaseInvAdded: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.UPD_PURCHASEINV: {
                    return {
                        ...state,
                        invUpdate: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPPLIER_PAYMENT: {
                    return {
                        ...state,
                        receipt: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.UPD_SUPPLIER_PAYMENT: {
                    return {
                        ...state,
                        upd_receipt: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.ADD_PURCHASE_RETURN: {
                    return {
                        ...state,
                        purchaseInvReturn: action.payload,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.UPD_PURCHASE_RETURN: {
                    return {
                        ...state,
                        purReturnUpdate: action.payload,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PurchaseActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
            
            
                case PurchaseActionTypes.SUPP_CREDIT_NOTE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addCredit: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.ADD_SERVICE_BILL: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedServiceBill: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.UPD_SERVICES_BILL: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updateServiceBill: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updCredit: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPP_DEBIT_NOTE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addDebit: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPP_UPD_DEBIT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updDebit: null,
                        loading: false,
                    };
                }
            
                
                case PurchaseActionTypes.ADD_Purchase: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invAdded: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.ADD_PURCHASEINV: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        purchaseInvAdded: null,
                        loading: true,
                    };
                }
                case PurchaseActionTypes.UPD_PURCHASEINV: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invUpdate: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.ADD_PURCHASE_RETURN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        purchaseInvReturn: null,
                        loading: true,
                    };
                }
                case PurchaseActionTypes.UPD_PURCHASE_RETURN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        purReturnUpdate: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.SUPPLIER_PAYMENT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        receipt: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.UPD_SUPPLIER_PAYMENT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        upd_receipt: null,
                        loading: false,
                    };
                }
                case PurchaseActionTypes.ADD_PURCHASEGRID: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        invGridAdded: null,
                        loading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case PurchaseActionTypes.SUPP_DEBIT_NOTE:
            return { ...state, loading: true, addDebit: null };
        case PurchaseActionTypes.SUPP_CREDIT_NOTE:
            return { ...state, loading: true, addCredit: null };
        case PurchaseActionTypes.ADD_SERVICE_BILL:
            return { ...state, loading: true, addedServiceBill: null };
        case PurchaseActionTypes.UPD_SERVICES_BILL:
            return { ...state, loading: true, updateServiceBill: null };
        case PurchaseActionTypes.SUPP_UPD_CREDIT_NOTE:
            return { ...state, loading: true, updCredit: null };
        case PurchaseActionTypes.SUPP_UPD_DEBIT:
            return { ...state, loading: true, updDebit: null };  
        case PurchaseActionTypes.ADD_Purchase:
            return { ...state, loading: true, invAdded: null };
/*        case PurchaseActionTypes.ADD_PURCHASEINV:
            return { ...state, loading: true, purchaseInvAdded: null };*/
        case PurchaseActionTypes.UPD_PURCHASEINV:
            return { ...state, loading: true, invUpdate: null };
        case PurchaseActionTypes.ADD_PURCHASEGRID:
            return { ...state, loading: true, invGridAdded: null };
        case PurchaseActionTypes.UPD_SUPPLIER_PAYMENT:
            return { ...state, loading: true, upd_receipt: null };
        case PurchaseActionTypes.SUPPLIER_PAYMENT:
            return { ...state, loading: true, receipt: null };
        case PurchaseActionTypes.ADD_PURCHASE_RETURN:
            return { ...state, loading: true, purchaseInvReturn: null };
        case PurchaseActionTypes.UPD_PURCHASE_RETURN:
            return { ...state, loading: true, purReturnUpdate: null };
        case PurchaseActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                invAdded: null,
                purchaseInvAdded: null,
                invUpdate: null,
                purchaseInvReturn: null,
                purReturnUpdate: null,
                receipt: null,
                upd_receipt: null,
                invGridAdded: null,
                addCredit:null,
                addedServiceBill:null,
                updateServiceBill:null,
                updCredit:null,
                addDebit:null,
                updDebit:null,
             };
        default:
            return { ...state };
    }
};

export default Purchase;
