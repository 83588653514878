import React, { Suspense,useState,useEffect } from 'react';
import axios from 'axios'
import { useRoutes } from 'react-router-dom';
// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';




// lazy load all the views
// auth
const componentMapping = {
Login : React.lazy(() => import('../pages/auth/Login')),
Register : React.lazy(() => import('../pages/auth/Register')),
Confirm : React.lazy(() => import('../pages/auth/Confirm')),
ForgetPassword : React.lazy(() => import('../pages/auth/ForgetPassword')),
LockScreen : React.lazy(() => import('../pages/auth/LockScreen')),

// dashboards
DashBoard1 : React.lazy(() => import('../pages/dashboards/DashBoard1/')),
PurchaseDashboard : React.lazy(() => import('../pages/dashboards/PurchaseDashboard/')),
SalesDashboard : React.lazy(() => import('../pages/dashboards/SalesDashbaord/')),
// Admin
FinancialYear : React.lazy(() => import('../pages/Admin/FinancialYear')),
UsersList : React.lazy(() => import('../pages/Admin/ManageUsers/UsersList')),
AddUser : React.lazy(() => import('../pages/Admin/ManageUsers/AddUser')),
EditUser : React.lazy(() => import('../pages/Admin/ManageUsers/EditUser')),
MenuRights : React.lazy(() => import('../pages/Admin/ManageUsers/MenuRights')),

ManageRole : React.lazy(() => import('../pages/Admin/ManageRole')),
ManageLoginPage : React.lazy(() => import('../pages/Admin/ManageLoginPage')),
AddRole : React.lazy(() => import('../pages/Admin/AddRole')),
EditRole : React.lazy(() => import('../pages/Admin/EditRole')),
ManagePDFFormat : React.lazy(() => import('../pages/Admin/ManagePDFFormat')),
ManageDashboardRight : React.lazy(() => import('../pages/Admin/ManageDashboardRight')),
UserLog : React.lazy(() => import('../pages/Admin/Reports/UserLog')),
CashCounterRight : React.lazy(() => import('../pages/Admin/CashCounterRight')),

// apps
CalendarApp : React.lazy(() => import('../pages/apps/Calendar')),
ChatApp : React.lazy(() => import('../pages/apps/Chat')),
Inbox : React.lazy(() => import('../pages/apps/Email/Inbox')),
Kanban : React.lazy(() => import('../pages/apps/Tasks/Board')),
AdminDetailBoard : React.lazy(() => import('../pages/apps/Tasks/Board/AdminDetailBoard')),
TaskList : React.lazy(() => import('../pages/apps/Tasks/TaskList')),
TaskDetail : React.lazy(() => import('../pages/apps/Tasks/Detail')),
AdminDetails : React.lazy(() => import('../pages/apps/Tasks/Detail/AdminDetails')),
Projects : React.lazy(() => import('../pages/apps/Projects')),
ContactList : React.lazy(() => import('../pages/apps/Contacts/List')),
Profile : React.lazy(() => import('../pages/apps/Contacts/Profile')),
ManagePosition : React.lazy(() => import('../pages/apps/Setups/ManagePosition')),
ManagePriority : React.lazy(() => import('../pages/apps/Setups/ManagePriority')),

// extra pages
Starter : React.lazy(() => import('../pages/other/Starter')),
Pricing : React.lazy(() => import('../pages/other/Pricing')),
Timeline : React.lazy(() => import('../pages/other/Timeline')),
Invoice : React.lazy(() => import('../pages/other/Invoice')),
FAQ : React.lazy(() => import('../pages/other/FAQ')),
Gallery : React.lazy(() => import('../pages/other/Gallery')),
Error404 : React.lazy(() => import('../pages/other/Error404')),
Error500 : React.lazy(() => import('../pages/other/Error500')),
Maintenance : React.lazy(() => import('../pages/other/Maintenance')),
ComingSoon : React.lazy(() => import('../pages/other/ComingSoon')),

// base ui
Buttons : React.lazy(() => import('../pages/uikit/Buttons')),
Cards : React.lazy(() => import('../pages/uikit/Cards')),
Avatars : React.lazy(() => import('../pages/uikit/Avatars')),
TabsAccordions : React.lazy(() => import('../pages/uikit/TabsAccordions')),
Notifications : React.lazy(() => import('../pages/uikit/Notifications')),
Modals : React.lazy(() => import('../pages/uikit/Modals')),
Progress : React.lazy(() => import('../pages/uikit/Progress')),
Offcanvases : React.lazy(() => import('../pages/uikit/Offcanvases')),
Placeholders : React.lazy(() => import('../pages/uikit/Placeholders')),
Spinners : React.lazy(() => import('../pages/uikit/Spinners')),
Images : React.lazy(() => import('../pages/uikit/Images')),
Carousel : React.lazy(() => import('../pages/uikit/Carousel')),
EmbedVedio : React.lazy(() => import('../pages/uikit/EmbedVideo')),
Dropdowns : React.lazy(() => import('../pages/uikit/Dropdowns')),
PopoversAndTooltips : React.lazy(() => import('../pages/uikit/PopoversAndTooltips')),
GeneralUI : React.lazy(() => import('../pages/uikit/GeneralUI')),
Typography : React.lazy(() => import('../pages/uikit/Typography')),
Grid : React.lazy(() => import('../pages/uikit/Grid')),

// widgets
Widgets : React.lazy(() => import('../pages/uikit/Widgets')),

// extended ui
RangeSliders : React.lazy(() => import('../pages/uikit/RangeSlider')),
NestableList : React.lazy(() => import('../pages/uikit/NestableList')),
SweetAlerts : React.lazy(() => import('../pages/uikit/SweetAlerts')),
Tourpage : React.lazy(() => import('../pages/uikit/TourPage')),
TreeViewExample : React.lazy(() => import('../pages/uikit/TreeView')),

// icons
FeatherIcons : React.lazy(() => import('../pages/icons/FeatherIcons')),
MDIIcons : React.lazy(() => import('../pages/icons/MDIIcons')),
Dripicons : React.lazy(() => import('../pages/icons/DripiIcons')),
FontAwesomeIcons : React.lazy(() => import('../pages/icons/FontAwesomeIcons')),
ThemifyIcons : React.lazy(() => import('../pages/icons/ThemifyIcons')),

// forms
GeneralElements : React.lazy(() => import('../pages/forms/Basic')),
FormAdvanced : React.lazy(() => import('../pages/forms/Advanced')),
Validation : React.lazy(() => import('../pages/forms/Validation')),
FormWizard : React.lazy(() => import('../pages/forms/Wizard')),
FileUpload : React.lazy(() => import('../pages/forms/FileUpload')),
Editors : React.lazy(() => import('../pages/forms/Editors')),
Grid1 : React.lazy(() => import('../pages/forms/Grid')),

// tables
BasicTable : React.lazy(() => import('../pages/tables/BasicTable')),
AdvancedTable : React.lazy(() => import('../pages/tables/AdvancedTable')),

// reports
CustomerBalanceRep : React.lazy(() => import('../pages/sales-reports/CustomerBalanceRep')),
ReceiptReport : React.lazy(() => import('../pages/sales-reports/ReceiptReport')),
ReceivablesAging : React.lazy(() => import('../pages/sales-reports/ReceivablesAging')),
CustomerDebitNoteDetails : React.lazy(() => import('../pages/sales-reports/CustomerDebitNoteDetails')),
CustomerDebitNoteSummary : React.lazy(() => import('../pages/sales-reports/CustomerDebitNoteSummary')),
CustomerCreditNoteSummary : React.lazy(() => import('../pages/sales-reports/CustomerCreditNoteSummary')),
CustomerCreditNoteDetails : React.lazy(() => import('../pages/sales-reports/CustomerCreditNoteDetails')),


// charts
ApexChart : React.lazy(() => import('../pages/chart/ApexChart')),
ChartJs : React.lazy(() => import('../pages/chart/ChartJs')),

// maps
GoogleMaps : React.lazy(() => import('../pages/maps/GoogleMaps')),
VectorMaps : React.lazy(() => import('../pages/maps/VectorMaps')),

// items
InactiveItems : React.lazy(() => import('../pages/itmmngt/InactiveItems')),
StockinDetails : React.lazy(() => import('../pages/itmmngt/StockinDetails')),
InventoryPrintVoucherList : React.lazy(() => import('../pages/itmmngt/InventoryPrintVoucherList')),
StockoutDetails : React.lazy(() => import('../pages/itmmngt/StockoutDetails')),
StockoutSummary : React.lazy(() => import('../pages/itmmngt/StockoutSummary')),
StockinSummary : React.lazy(() => import('../pages/itmmngt/StockinSummary')),
StockAdjustmentSummary : React.lazy(() => import('../pages/itmmngt/StockAdjustmentSummary')),
StockAdjustmentDetails : React.lazy(() => import('../pages/itmmngt/StockAdjustmentDetails')),
TransferSummary : React.lazy(() => import('../pages/itmmngt/TransferSummary')),
TransferDetails : React.lazy(() => import('../pages/itmmngt/TransferDetails')),
addImeiStock : React.lazy(() => import('../pages/itmmngt/addImeiStock')),
ItemList : React.lazy(() => import('../pages/itmmngt/ItemList')),
ItemExcelImport : React.lazy(() => import('../pages/itmmngt/ItemExcelImport')),
AddItem : React.lazy(() => import('../pages/itmmngt/AddItem')),
EditItem : React.lazy(() => import('../pages/itmmngt/EditItem')),
ItemBrand : React.lazy(() => import('../pages/itmmngt/ItemBrand')),
ItemCategory : React.lazy(() => import('../pages/itmmngt/ItemCategory')),
MultipleItemsAdd : React.lazy(() => import('../pages/itmmngt/MultipleItemsAdd')),
MultipleItemUpdate : React.lazy(() => import('../pages/itmmngt/MultipleItemUpdate')),
ItemSubCategory : React.lazy(() => import('../pages/itmmngt/ItemSubCategory')),
ManageItemType : React.lazy(() => import('../pages/itmmngt/ManageItemType')),
ManageItemPrice : React.lazy(() => import('../pages/itmmngt/ManageItemPrice')),
ManageItemCode : React.lazy(() => import('../pages/itmmngt/ManageItemCode')),
ManageStockLocations : React.lazy(() => import('../pages/itmmngt/ManageStockLocations')),
ManageUnit : React.lazy(() => import('../pages/itmmngt/ManageUnit')),
ItemLedger : React.lazy(() => import('../pages/inventory-reports/ItemLedger')),
StockReport : React.lazy(() => import('../pages/inventory-reports/StockReport')),
StockValueReport : React.lazy(() => import('../pages/inventory-reports/StockValueReport')),
ManageColors : React.lazy(() => import('../pages/itmmngt/ManageColors')),


// Opening Stock
// OpeningStockList : React.lazy(() => import('../pages/itmmngt/OpeningStockList')),
EditOpeningStock : React.lazy(() => import('../pages/itmmngt/EditOpeningStock')),
AddOpeningStock : React.lazy(() => import('../pages/itmmngt/AddOpeningStock')),

// Stock Adjustment
AddStockAdjustment : React.lazy(() => import('../pages/itmmngt/AddStockAdjustment')),
StockAdjustmentList : React.lazy(() => import('../pages/itmmngt/StockAdjustmentList')),
EditStockAdjustment : React.lazy(() => import('../pages/itmmngt/EditStockAdjustment')),

// Stock Transfer
StockTransferList : React.lazy(() => import('../pages/itmmngt/StockTransferList')),
EditStockTransfer : React.lazy(() => import('../pages/itmmngt/EditStockTransfer')),
AddStockTransfer : React.lazy(() => import('../pages/itmmngt/AddStockTransfer')),

// Stock In
AddStockIn : React.lazy(() => import('../pages/itmmngt/AddStockIn')),
StockInList : React.lazy(() => import('../pages/itmmngt/StockInList')),
EditStockIn : React.lazy(() => import('../pages/itmmngt/EditStockIn')),

// Stock Out
AddStockOut : React.lazy(() => import('../pages/itmmngt/AddStockOut')),
StockOutList : React.lazy(() => import('../pages/itmmngt/StockOutList')),
EditStockOut : React.lazy(() => import('../pages/itmmngt/EditStockOut')),


// customers
AddCustomer : React.lazy(() => import('../pages/Customer/AddCustomer')),
PosInvoiceList : React.lazy(() => import('../pages/Customer/PosInvoiceList')),
CustomerList : React.lazy(() => import('../pages/Customer/CustomerList')),
CustomerLedger : React.lazy(() => import('../pages/Customer/CustomerLedger')),
EditCustomer : React.lazy(() => import('../pages/Customer/EditCustomer')),
CustomerGroup : React.lazy(() => import('../pages/Customer/CustomerGroup')),
SetInvoiceID : React.lazy(() => import('../pages/Customer/SetInvoiceID')),
SetSalesPerson : React.lazy(() => import('../pages/Customer/SetSalesPerson')),
ManageSaleCat : React.lazy(() => import('../pages/Customer/ManageSaleCat')),
CustomerAllVoucherList : React.lazy(() => import('../pages/Customer/CustomerAllVoucherList')),
AddCustomerOpeningBalance : React.lazy(() => import('../pages/Customer/AddCustomerOpeningBalance')),
// CustomerBalance_list : React.lazy(() => import('../pages/Customer/CustomerBalance_list')),
MultipleCustomerAdd : React.lazy(() => import('../pages/Customer/MultipleCustomerAdd')),
MultipleCustomerUpdate : React.lazy(() => import('../pages/Customer/MultipleCustomerUpdate')),
ExcelImport : React.lazy(() => import('../pages/Customer/ExcelImport')),
InactiveCustomer : React.lazy(() => import('../pages/Customer/InactiveCustomer')),
Edit_CustomerBalance : React.lazy(() => import('../pages/Customer/Edit_CustomerBalance')),
Manage_Carrier : React.lazy(() => import('../pages/Customer/Manage_Carrier')),
Manage_CashCounter : React.lazy(() => import('../pages/Customer/Manage_CashCounter')),
CounterSale : React.lazy(() => import('../pages/Customer/CounterSale')),
PostVoucherList : React.lazy(() => import('../pages/Customer/PostVoucherList')),

// customers
AddSaleInvoice : React.lazy(() => import('../pages/invoices/addSaleInv')),
PosConfigurations : React.lazy(() => import('../pages/invoices/PosConfigurations')),
SaleInvoiceList : React.lazy(() => import('../pages/invoices/SaleInv_List')),
EditSaleInv : React.lazy(() => import('../pages/invoices/EditSaleInv')),
InvoiceReturnList : React.lazy(() => import('../pages/invoices/InvoiceReturnList')),
AddInvoiceReturn : React.lazy(() => import('../pages/invoices/AddInvoiceReturn')),
EditInvReturn : React.lazy(() => import('../pages/invoices/EditInvReturn')),
// analytics 

/// Flow Chart and Reoprts List
Flowchart : React.lazy(() => import('../pages/Flowchart')),
ReportList : React.lazy(() => import('../pages/sales-analysis/ReportList')),

ItemWiseSales : React.lazy(() => import('../pages/sales-analysis/ItemWiseSales')),
InvoicesDetail : React.lazy(() => import('../pages/sales-analysis/InvoicesDetail')),
InvoiceSummary : React.lazy(() => import('../pages/sales-analysis/InvoiceSummary')),
SalesReturnSummary : React.lazy(() => import('../pages/sales-analysis/SalesReturnSummary')),
SalesReturnDetails : React.lazy(() => import('../pages/sales-analysis/SalesReturnDetails')),
SalesandRecovery : React.lazy(() => import('../pages/sales-analysis/SalesandRecovery')),
CustomerWiseSales : React.lazy(() => import('../pages/sales-analysis/CustomerWiseSales')),
SupplierWisePurchase : React.lazy(() => import('../pages/purchase-analysis/SupplierWisePurchase')),
PurchaseSummary : React.lazy(() => import('../pages/purchase-analysis/PurchaseSummary')),
PurchasePayment : React.lazy(() => import('../pages/purchase-analysis/PurchasePayment')),

PurchaseDetails : React.lazy(() => import('../pages/purchase-analysis/PurchaseDetails')),
PurchaseReturnSummary : React.lazy(() => import('../pages/purchase-analysis/PurchaseReturnSummary')),
PurchaseReturnDetails : React.lazy(() => import('../pages/purchase-analysis/PurchaseReturnDetails')),
SupplierPaymentRpt : React.lazy(() => import('../pages/supplier-reports/SupplierPaymentRpt')),
ItemWisePurchase : React.lazy(() => import('../pages/purchase-analysis/ItemWisePurchase')),
Analytsis_Table : React.lazy(() => import('../pages/sales-analysis/Analytsis_Table')),
ServicesBillSummary : React.lazy(() => import('../pages/supplier-reports/ServicesBillSummary')),
ServicesBillDetails : React.lazy(() => import('../pages/supplier-reports/ServicesBillDetails')),


// employee
AddEmployee : React.lazy(() => import('../pages/employee/addEmployee')),
EmployeeList : React.lazy(() => import('../pages/employee/employeeList')),
EditEmployee : React.lazy(() => import('../pages/employee/editEmployee')),
Manage_Bonustype : React.lazy(() => import('../pages/employee/Manage_Bonustype')),
Manage_Jobtype : React.lazy(() => import('../pages/employee/Manage_Jobtype')),
Manage_Assettype : React.lazy(() => import('../pages/employee/Manage_Assettype')),
Manage_Doctype : React.lazy(() => import('../pages/employee/Manage_Doctype')),
Manage_Designation : React.lazy(() => import('../pages/employee/Manage_Designation')),
Manage_Department : React.lazy(() => import('../pages/employee/Manage_Department')),

// Warranty 
WarrantyReceived : React.lazy(() => import('../pages/warranty/warranty/WarrantyReceived')),
WarrantyReceived_list : React.lazy(() => import('../pages/warranty/warranty/WarrantyReceived_list')),
WarrantyReceived_edit : React.lazy(() => import('../pages/warranty/warranty/WarrantyReceived_edit')),
WarrantySend : React.lazy(() => import('../pages/warranty/warranty/WarrantySend')),
WarrantySend_list : React.lazy(() => import('../pages/warranty/warranty/WarrantySend_list')),
WarrantySend_edit : React.lazy(() => import('../pages/warranty/warranty/WarrantySend_edit')),
WarrantyReturn_add : React.lazy(() => import('../pages/warranty/warranty/WarrantyReturn_add')),
WarrantyReturn_edit : React.lazy(() => import('../pages/warranty/warranty/WarrantyReturn_edit')),
WarrantyHandover_add : React.lazy(() => import('../pages/warranty/warranty/WarrantyHandover_add')),
WarrantyHandover_list : React.lazy(() => import('../pages/warranty/warranty/WarrantyHandover_list')),
WarrantyReturn_list : React.lazy(() => import('../pages/warranty/warranty/WarrantyReturn_list')),
WarrantyHandover_edit : React.lazy(() => import('../pages/warranty/warranty/WarrantyHandover_edit')),
ManageFaults : React.lazy(() => import('../pages/warranty/setups/ManageFaults')),
MultiplePrint : React.lazy(() => import('../pages/warranty/setups/MultiplePrint')),
ManageDeviceType : React.lazy(() => import('../pages/warranty/setups/ManageDeviceType')),
ManageWarrantyStatus : React.lazy(() => import('../pages/warranty/setups/ManageWarrantyStatus')),



// Warranty Received Report
StatusReport : React.lazy(() => import('../pages/warranty/reports/StatusReport')),
IMEIDetailsReport : React.lazy(() => import('../pages/warranty/reports/IMEIDetailsReport')),

ReceivedSummary : React.lazy(() => import('../pages/warranty/reports/ReceivedSummary')),
SentSummary : React.lazy(() => import('../pages/warranty/reports/SentSummary')),
ReturnSummary : React.lazy(() => import('../pages/warranty/reports/ReturnSummary')),
HandoverSummary : React.lazy(() => import('../pages/warranty/reports/HandoverSummary')),
ReceivedDetails : React.lazy(() => import('../pages/warranty/reports/ReceivedDetails')),
SentDetails : React.lazy(() => import('../pages/warranty/reports/SentDetails')),
ReturnDetails : React.lazy(() => import('../pages/warranty/reports/ReturnDetails')),
HandoverDetails : React.lazy(() => import('../pages/warranty/reports/HandoverDetails')),


// suppliers
AddSupplier : React.lazy(() => import('../pages/Supplier/AddSupplier')),
SupplierAllVoucherList : React.lazy(() => import('../pages/Supplier/SupplierAllVoucherList')),
SupplierList : React.lazy(() => import('../pages/Supplier/SupplierList')),
EditSupplier : React.lazy(() => import('../pages/Supplier/EditSupplier')),
SetSupplierId : React.lazy(() => import('../pages/Supplier/SetSupplierId')),
SupplierGroup : React.lazy(() => import('../pages/Supplier/SupplierGroup')),
MultipleSupplierUpdate : React.lazy(() => import('../pages/Supplier/MultipleSupplierUpdate')),
MultipleSupplierAdd : React.lazy(() => import('../pages/Supplier/MultipleSupplierAdd')),
ImportSupplierExcel : React.lazy(() => import('../pages/Supplier/ImportSupplierExcel')),
InactiveSupplierList : React.lazy(() => import('../pages/Supplier/InactiveSupplierList')),
AddSupplierOpeningBalance : React.lazy(() => import('../pages/Supplier/AddSupplierOpeningBalance')),
SupplierPostVoucherList : React.lazy(() => import('../pages/Supplier/SupplierPostVoucherList')),

// suppliers-reports
SupplierLedger : React.lazy(() => import('../pages/supplier-reports/SupplierLedger')),
SupplierTable : React.lazy(() => import('../pages/supplier-reports/SupplierTable')),
SupplierBalanceRep : React.lazy(() => import('../pages/supplier-reports/SupplierBalanceRep')),
PayablesAging : React.lazy(() => import('../pages/supplier-reports/PayablesAging')),
DebitNoteSummary : React.lazy(() => import('../pages/supplier-reports/DebitNoteSummary')),
DebitNoteDetails : React.lazy(() => import('../pages/supplier-reports/DebitNoteDetails')),
CreditNoteSummary : React.lazy(() => import('../pages/supplier-reports/CreditNoteSummary')),
CreditNoteDetails : React.lazy(() => import('../pages/supplier-reports/CreditNoteDetails')),

// Purchase
addPurchase : React.lazy(() => import('../pages/purchase/addPurchase')),
AddPurchaseReturn : React.lazy(() => import('../pages/purchase/AddPurchaseReturn')),
EditPurchaseInv : React.lazy(() => import('../pages/purchase/EditPurchaseInv')),
SupplierPayment : React.lazy(() => import('../pages/purchase/SupplierPayment')),
EditSupplierPayment : React.lazy(() => import('../pages/purchase/EditSupplierPayment')),
SupplierPaymentList : React.lazy(() => import('../pages/purchase/SupplierPaymentList')),
PurchaseInv_List : React.lazy(() => import('../pages/purchase/PurchaseInv_List')),
PurchaseReturnList : React.lazy(() => import('../pages/purchase/PurchaseReturnList')),
EditPurchaseReturn : React.lazy(() => import('../pages/purchase/EditPurchaseReturn')),


SupplierCreditNote : React.lazy(() => import('../pages/purchase/SupplierCreditNote')),
AddServicesBill : React.lazy(() => import('../pages/purchase/AddServicesBill')),
SupplierDebitNote : React.lazy(() => import('../pages/purchase/SupplierDebitNote')),
SupplierCreditNoteList : React.lazy(() => import('../pages/purchase/SupplierCreditNoteList')),
ServicesBillList : React.lazy(() => import('../pages/purchase/ServicesBillList')),
EditServicesBill : React.lazy(() => import('../pages/purchase/EditServicesBill')),
SupplierDebitNoteList : React.lazy(() => import('../pages/purchase/SupplierDebitNoteList')),
SupplierEditCreditNote : React.lazy(() => import('../pages/purchase/SupplierEditCreditNote')),
SupplierEditDebitNote : React.lazy(() => import('../pages/purchase/SupplierEditDebitNote')),


// Ledger 
GeneralLedger : React.lazy(() => import('../pages/Ledger/Reports/GeneralLedger')),
BalanceSheet : React.lazy(() => import('../pages/Ledger/Reports/BalanceSheet')),
BusinessOverview : React.lazy(() => import('../pages/Ledger/BusinessOverview')),
ProfitandLoss : React.lazy(() => import('../pages/Ledger/Reports/ProfitandLoss')),
TrialBalance : React.lazy(() => import('../pages/Ledger/Reports/TrialBalance')),
Daybook : React.lazy(() => import('../pages/Ledger/Reports/Daybook')),
Cashbook : React.lazy(() => import('../pages/Ledger/Reports/Cashbook')),
Bankbook : React.lazy(() => import('../pages/Ledger/Reports/Bankbook')),
AccountOpeningBal : React.lazy(() => import('../pages/Ledger/Setups/AccountOpeningBal')),
ManageAccountType : React.lazy(() => import('../pages/Ledger/Setups/ManageAccountType')),
AccountsPrintVoucherList : React.lazy(() => import('../pages/Ledger/Setups/AccountsPrintVoucherList')),
AddLedgerAccount : React.lazy(() => import('../pages/Ledger/ChartOfAccounts/AddLedgerAccount')),
ManageDetailAcount : React.lazy(() => import('../pages/Ledger/ChartOfAccounts/ManageDetailAcount')),
InactiveAccounts : React.lazy(() => import('../pages/Ledger/ChartOfAccounts/InactiveAccounts')),
InactiveGroupAcount : React.lazy(() => import('../pages/Ledger/ChartOfAccounts/InactiveGroupAcount')),
AccountList : React.lazy(() => import('../pages/Ledger/ChartOfAccounts/AccountList')),
AccountBalanceSummary : React.lazy(() => import('../pages/Ledger/Reports/AccountBalanceSummary')),


// Jounral Entries
// Cash Payments
CashPayment : React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment')),
CashPayment_edit : React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_edit')),
CashPayment_List : React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_List')),
CashPayment_Summary : React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_Summary')),
CashPayment_Details : React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_Details')),
CashReceipt_Details : React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_Details')),

JournalVoucher_Details : React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_Details')),
ExpenseVoucher_Details : React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_Details')),
BankReceipt_Details : React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_Details')),
BankPayment_Details : React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_Details')),

BankPayment : React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment')),
BankPayment_edit : React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_edit')),
BankPayment_List : React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_List')),
BankPayment_Summary : React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_Summary')),

// Vouchers
JournalVoucher : React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher')),
JournalVoucher_List : React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_List')),
JournalVoucher_edit : React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_edit')),
JournalVoucher_Summary : React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_Summary')),


ExpenseVoucher : React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher')),
ExpenseVoucher_List : React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_List')),
ExpenseVoucher_edit : React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_edit')),
ExpenseVoucher_Summary : React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_Summary')),

AccountsPostVoucherList : React.lazy(() => import('../pages/Ledger/Setups/AccountsPostVoucherList')),

// Cash Receipt
CashReceipt : React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt')),
CashReceipt_edit : React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_edit')),
CashReceipt_List : React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_List')),
CashReceipt_Summary : React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_Summary')),

BankReceipt : React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt')),
BankReceipt_edit : React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_edit')),
BankReceipt_List : React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_List')),
BankReceipt_Summary : React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_Summary')),

// customers payments
AddCustPay : React.lazy(() => import('../pages/cusPayments/addCustPay')),
CustomerRec_list : React.lazy(() => import('../pages/cusPayments/CustomerRec_list')),
CustPay_Edit : React.lazy(() => import('../pages/cusPayments/CustPay_Edit')),

// credit & Debit note
CreditNote : React.lazy(() => import('../pages/cusPayments/CreditNote')),
DebitNote : React.lazy(() => import('../pages/cusPayments/DebitNote')),
CreditNoteList : React.lazy(() => import('../pages/cusPayments/CreditNoteList')),
DebitNoteList : React.lazy(() => import('../pages/cusPayments/DebitNoteList')),
EditCreditNote : React.lazy(() => import('../pages/cusPayments/EditCreditNote')),
EditDebitNote : React.lazy(() => import('../pages/cusPayments/EditDebitNote')),

// lamding
Landing : React.lazy(() => import('../pages/Landing')),
}
const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {
    if(Component){
    return <Suspense fallback={loading()}>
        <Component />
    </Suspense>
        
    }
};

const AllRoutes = () => {
  const { appSelector } = useRedux();
  const { layout ,user} = appSelector((state) => ({
    layout: state.Layout,
    user: state.Auth.user,
  }));

   let [getRoutes, setRoutes] = useState([]);
  let [getRole, setRole] = useState(null);
 useEffect(()=>{
    if(user){
        if(user.get_routes.original){
            setRoutes(user.get_routes.original.menus)
            setRole({role:user.role,id:user.userid})
        }
    }
     
  },[user])
  

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();

  // Define the base routes
  let protectedRoutes = [
    { path: '/', element: <Root /> },
    {
      path: '/',
      element: <DefaultLayout />,
      children: [
        { path: 'auth/login', element: <LoadComponent component={componentMapping['Login']} /> },
        getRoutes.length>0&&{
          path: '*',
          element: <LoadComponent component={componentMapping['Error404']} />,
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute roles={getRole?.role} id={getRole?.id} component={Layout} />,
      children: [{
                path: '/dashboard',
                element: <LoadComponent component={componentMapping['DashBoard1']} />,
            }],
    },
  ];

  // Dynamically generate and add routes
  getRoutes.forEach((route) => {
    protectedRoutes[2].children.push({
      path: route.path,
      element: <LoadComponent component={componentMapping[route.component]} />,
    });
  });

  return useRoutes(protectedRoutes);
};

export { AllRoutes };
