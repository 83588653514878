import { APICore } from './apiCore';

const api = new APICore();


function addPurchaseInv(params: { entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string }) {
  
    const baseUrl = '/add-purchase';
    return api.create(`${baseUrl}`, params);
}
function updPurchaseInv(params: { id:string,entrydate:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string }) {
    
    const baseUrl = `/update-purchase/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addPurchaseReturn_API(params: { entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string}) {
  
    const baseUrl = '/add-purchasereturn';
    return api.create(`${baseUrl}`, params);
}
function updPurchaseReturn_API(params: { id:string,entrydate:string,purchasenumber:string,supplierid:string,transtype:string,reference:string,notes:string,freight:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,netamount:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,purchasedetdata:Object,invoicetermsid:string,shipped_via:string,trackNumber:string,tax:string,taxamount:string }) {
    
    const baseUrl = `/update-purchasereturn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function add_supplierPayment(params) {
    
    const baseUrl = `/add-supplier-payment`;
    return api.create(`${baseUrl}`, params);
}

function upd_supplierPayment(params) {
    
    const baseUrl = `/update-supplier-payment/${params.id}`;
    return api.update(`${baseUrl}`, params);
}

function supAddCedit_API(params) {
    const baseUrl = '/add-supplier-creditnote';
    return api.create(`${baseUrl}`, params);
    
}
function supServiceBill_API(params) {
    const baseUrl = '/add-supplier-servicesbill';
    return api.create(`${baseUrl}`, params);
    
}
function supUpdCedit_API(params) {
    const baseUrl = '/update-supplier-creditnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function UpdServiceBill_API(params) {
    const baseUrl = '/update-supplier-servicesbill/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function supAddDebit_API(params) {
    const baseUrl = '/add-supplier-debitnote';
    return api.create(`${baseUrl}`, params);
    
}
function supUpdDebit_API(params) {
    const baseUrl = '/update-supplier-debitnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}

export {addPurchaseInv,updPurchaseInv,add_supplierPayment,upd_supplierPayment,addPurchaseReturn_API, supAddCedit_API,
    supUpdCedit_API,
    supAddDebit_API,
    supUpdDebit_API,
    UpdServiceBill_API,
    supServiceBill_API,
updPurchaseReturn_API};
